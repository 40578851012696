<template>
  <div class="view-home winner-page">

    <div class="teachers-main">
      <div class="container position-relative">
        <nav class="breadcrumbs-block">
          <ul class="breadcrumbs-list">
            <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
            <li class="breadcrumbs-item">{{ $t('for-teachers.subtitle') }}</li>
          </ul>
        </nav>
        <div class="teachers-header">
          <div class="teachers-header-left">
            <div class="title">{{ $t('for-teachers.subtitle') }}</div>
          </div>
          <div class="teachers-header-right">
            <p class="text">
              {{ $t('for-parents.info-2') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <section class="goals-block">
      <div class="container d-flex justify-content-between flex-md-row flex-column">
        <div class="goals-left">
          <div class="goals-title">
            {{ $t('purposeQabiletService1') }}
          </div>
          <div class="goals-text">
            {{ $t('purposeQabiletService2') }}
          </div>
          <div class="goals-text">
            {{ $t('purposeQabiletService3') }}
          </div>
          <div class="goals-text">
            {{ $t('purposeQabiletService4') }}
          </div>
        </div>
        <div class="goals-right">
          <div class="goals-title">
            {{ $t('tasksQabiletService1') }}
          </div>
          <div class="goals-text" v-html="$t('tasksQabiletService2')">

          </div>
        </div>
      </div>
    </section>

<!--    <section class="soft-skills">-->
<!--      <div class="container">-->
<!--        <div class="row">-->
<!--          <div class="col-lg-6">-->
<!--            <div class="line-top"></div>-->
<!--            <div class="title">-->
<!--              Soft skills-->
<!--            </div>-->
<!--            <div class="text">-->
<!--              О мягких навыках - Soft skills — надпрофессиональные навыки, которые помогают решать жизненные задачи и-->
<!--              работать с другими людьми.-->
<!--            </div>-->
<!--            <a class="link">-->
<!--              Узнать подробнее-->
<!--            </a>-->
<!--          </div>-->
<!--          <div class="col-lg-6 d-none d-xl-block">-->
<!--            <el-image-->
<!--                style="width: 100%; height: 70%"-->
<!--                src="/images/students-discussion-together.png"-->
<!--                fit="contain"></el-image>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

    <section class="advantages">
      <div class="container">
        <div class="line-top"></div>
        <div class="title">
          {{ $t('benefitsTeacher') }}
        </div>
        <carousel :per-page="teachersSliderPerPage" :navigation-enabled="true" :paginationEnabled="false">
          <slide class="advantages-slide" v-for="item in advantagesTeacher">
            <router-link :to="/teachers-list/ + item.id" class="advantages-item">
              <el-image
                  style="width: 100%; height: 100%;"
                  :src="backUrl + item.img"
                  fit="fit"></el-image>
              <div class="advantages-item-title">
                {{item[`title_${$i18n.locale}`]}}
              </div>
              <div class="advantages-item-text">
                {{item[`text_${$i18n.locale}`]}}
              </div>
            </router-link>
          </slide>
        </carousel>
      </div>
    </section>

    <section class="teachers-tests">
      <div class="container tests-block">
        <div class="tests-block-line">
          <svg width="2" height="139" viewBox="0 0 2 139" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1.20397" y1="0.5" x2="1.20398" y2="138.167" stroke="#D2D2D2" stroke-width="0.933333"/>
          </svg>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="test-item-right">
              <div class="title">
                {{ $t('for-teachers.info-right-title-2') }}
              </div>
              <div class="text">
                {{ $t('toolForIdentifying') }}
              </div>
              <div class="tests">
                <div class="row">
                  <div class="col-4">
                    <p class="active">
                      {{ $t('tests') }}:
                    </p>
                    <p>
                      10-11 {{ $t('years') }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      5-6 {{ $t('years') }}
                    </p>
                    <p>
                      12-14 {{ $t('years') }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      7-9 {{ $t('years') }}
                    </p>
                    <p>
                      15-17 {{ $t('years') }}
                    </p>
                  </div>
                </div>
              </div>
          </div>
<!--            <div class="youtube-block">-->
<!--              <iframe src="https://www.youtube.com/embed/yxSRdOYErQ0?si=tbd1NbAedDqsHQuE"-->
<!--                      title="YouTube video player" frameborder="0" class="youtube-video"-->
<!--                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"-->
<!--                      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>-->
<!--            </div>-->
          </div>
          <div class="col-lg-6">
            <div class="test-item-left">
              <div class="title">
                {{ $t('for-teachers.info-right-title') }}
              </div>
              <div class="text">
                {{ $t('for-teachers.info-right-desc') }}
              </div>
              <div class="prof-test-img">

              </div>
            </div>
<!--            <div class="youtube-block">-->
<!--              <iframe src="https://www.youtube.com/embed/yxSRdOYErQ0?si=tbd1NbAedDqsHQuE"-->
<!--                      title="YouTube video player" frameborder="0" class="youtube-video"-->
<!--                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"-->
<!--                      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </section>

    <section class="teachers-latest-events">
      <div class="container">
        <div class="line-top"></div>
        <div class="title">
          {{ $t('latestEvents') }}
        </div>
        <div class="teachers-events-list">
          <router-link v-if="allInfo[0]" :to="typeEvent(allInfo[0].type, allInfo[0].id)" class="teachers-events-item">
<!--            <el-image-->
<!--                style="width: 100%; height: 100%"-->
<!--                src="/images/forums-slide-img.png"-->
<!--                fit="fit"></el-image>-->
            <img :src="getImagePath(allInfo[0].img || allInfo[0].banner)" width="100%" height="100%" alt=""/>
            <div class="teachers-events-item-date" v-if="allInfo[0].date_from">
              {{ $t('dateOfEvent') }}: {{formattedDate(allInfo[0].date_from, allInfo[0].date_to)}}
            </div>
            <div class="teachers-events-item-date" v-if="allInfo[0].start_date">
              {{ $t('dateOfEvent') }}: {{formattedDate(allInfo[0].start_date, allInfo[0].end_date)}}
            </div>
            <div class="teachers-events-item-title">
              {{ allInfo[0][`title_${$i18n.locale}`] || allInfo[0].title_ru || allInfo[0][`name_${$i18n.locale}`] || allInfo[0].name_ru}}
            </div>
            <div class="teachers-events-item-desc">
              {{ allInfo[0][`text_${$i18n.locale}`] || allInfo[0].text_ru || allInfo[0][`goal_${$i18n.locale}`] || allInfo[0].goal_ru || allInfo[0][`description_${$i18n.locale}`] || allInfo[0].description_ru}}
            </div>
            <div class="teachers-events-item-address">
              {{ $t('venue') }}:
              {{ allInfo[0][`venue_${$i18n.locale}`] || allInfo[0].venue_ru || allInfo[0][`location_${$i18n.locale}`] || allInfo[0].location_ru }}
            </div>
          </router-link>
          <router-link v-if="allInfo[1]" :to="typeEvent(allInfo[1].type, allInfo[1].id)" class="teachers-events-item">
            <img :src="getImagePath(allInfo[1].img || allInfo[1].banner)" width="100%" height="100%" alt=""/>
            <div class="teachers-events-item-date" v-if="allInfo[1].date_from">
              {{ $t('dateOfEvent') }}: {{formattedDate(allInfo[1].date_from, allInfo[1].date_to)}}
            </div>
            <div class="teachers-events-item-date" v-if="allInfo[1].start_date">
              {{ $t('dateOfEvent') }}: {{formattedDate(allInfo[1].start_date, allInfo[1].end_date)}}
            </div>
            <div class="teachers-events-item-title">
              {{ allInfo[1][`title_${$i18n.locale}`] || allInfo[1].title_ru || allInfo[1][`name_${$i18n.locale}`] || allInfo[1].name_ru}}
            </div>
            <div class="teachers-events-item-desc">
              {{ allInfo[1][`text_${$i18n.locale}`] || allInfo[1].text_ru || allInfo[1][`goal_${$i18n.locale}`] || allInfo[1].goal_ru || allInfo[1][`description_${$i18n.locale}`] || allInfo[1].description_ru }}
            </div>
            <div class="teachers-events-item-address">
              {{ $t('venue') }}: {{ allInfo[1][`venue_${$i18n.locale}`] || allInfo[1].venue_ru || allInfo[1][`location_${$i18n.locale}`] || allInfo[1].location_ru }}
            </div>
          </router-link>
          <router-link v-if="allInfo[2]" :to="typeEvent(allInfo[2].type, allInfo[2].id)" class="teachers-events-item">
            <img :src="getImagePath(allInfo[2].img || allInfo[2].banner)" width="100%" height="100%" alt=""/>
            <div class="teachers-events-item-date" v-if="allInfo[2].date_from">
              {{ $t('dateOfEvent') }}: {{formattedDate(allInfo[2].date_from, allInfo[2].date_to)}}
            </div>
            <div class="teachers-events-item-date" v-if="allInfo[2].start_date">
              {{ $t('dateOfEvent') }}: {{formattedDate(allInfo[2].start_date, allInfo[2].end_date)}}
            </div>
            <div class="teachers-events-item-title">
              {{ allInfo[2][`title_${$i18n.locale}`] || allInfo[2].title_ru || allInfo[2][`name_${$i18n.locale}`] || allInfo[2].name_ru}}
            </div>
            <div class="teachers-events-item-desc">
              {{ allInfo[2][`text_${$i18n.locale}`] || allInfo[2].text_ru || allInfo[2][`goal_${$i18n.locale}`] || allInfo[2].goal_ru || allInfo[2][`description_${$i18n.locale}`] || allInfo[2].description_ru}}
            </div>
            <div class="teachers-events-item-address">
              {{ $t('venue') }}: {{ allInfo[2][`venue_${$i18n.locale}`] || allInfo[2].venue_ru || allInfo[2][`location_${$i18n.locale}`] || allInfo[2].location_ru }}
            </div>
          </router-link>
        </div>
      </div>
    </section>

    <section class="teachers-get-test">
      <div class="container">
        <div class="title" v-html="$t('for-teachers.about-testing-title-2')">

        </div>
        <div class="test-steps">
          <div class="test-step">
            <div class="test-step-num">
              1
            </div>
            <div class="test-step-text">
              {{ $t('for-teachers.about-testing-1') }}
            </div>
          </div>
          <div class="test-step">
            <div class="test-step-num">
              2
            </div>
            <div class="test-step-text">
              {{ $t('for-teachers.about-testing-2') }}
            </div>
          </div>
        </div>
        <router-link :to="getTestedLink" class="link">
          {{ $t('for-teachers.test-btn') }}
        </router-link>
      </div>
    </section>
    <ScrollTopButton />
  </div>
</template>
<script>
import ScrollTopButton from "../../components/ScrollTop.vue";
import {Carousel, Slide} from 'vue-carousel';
export default {
  components: {
    ScrollTopButton,
    Carousel,
    Slide
  },
  data() {
    return {
      teachersSliderPerPage: 4,
      logged: false,
      getTestedLink: localStorage.getItem('token') ? "/testing" : "/signin",
      eventsList: [],
      seminarList: [],
      trainingList: [],
      refresherCourseList: [],
      allInfo: [],
      advantagesTeacher: [],
      backUrl: window.API_ROOT + '/storage/',
    }
  },
  methods: {
    getImagePath(path) {
      if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
      } else {
        return this.backUrl + path;
      }
    },
    typeEvent(type, id) {
      if (type == 'forum') {
        return `/one-forum/${id}`
      } else if (type == 'refresher_course') {
        return `/one-training/${id}`
      } else {
        return `/one-seminar/${type}/${id}`
      }
    },
    formattedDate(date_from, date_to) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const dateFrom = new Date(date_from).toLocaleDateString('ru-RU', options);
      const dateTo = new Date(date_to).toLocaleDateString('ru-RU', options);
      return `${dateFrom} - ${dateTo} гг`;
    }
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') === null || localStorage.getItem('token') === '');

    if (window.innerWidth > 900) {
      this.teachersSliderPerPage = 4
    } else if (window.innerWidth > 500) {
      this.teachersSliderPerPage = 2
    } else {
      this.teachersSliderPerPage = 1
    }

    const forumList = this.$http.get(window.API_ROOT + '/api/events/forum/list');
    const seminarList = this.$http.get(window.API_ROOT + '/api/events/seminar/list');
    const trainingList = this.$http.get(window.API_ROOT + '/api/events/training/list');
    const refresherCourseList = this.$http.get(window.API_ROOT + '/api/events/refresher-course/list');

    Promise.all([forumList, seminarList, trainingList, refresherCourseList])
        .then((responses) => {

          let [forumData, seminarData, trainingData, refresherCourseData] = responses.map(response => response.data);

          forumData = forumData.map(item => ({...item, type: 'forum'}))
          seminarData = seminarData.map(item => ({...item, type: 'seminar'}))
          trainingData = trainingData.map(item => ({...item, type: 'training'}))
          refresherCourseData = refresherCourseData.map(item => ({...item, type: 'refresher_course'}))

          this.allInfo = [
            ...forumData,
            ...seminarData,
            ...trainingData,
            ...refresherCourseData
          ];

          this.allInfo = this.allInfo.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

        })
        .catch((error) => {
          console.error('Ошибка', error);
        });

    // this.$http.get(window.API_ROOT + '/api/events/forum/list')
    //     .then(res => {
    //       this.eventsList = res.body
    //       this.allInfo.push(...res.body)
    //     })
    //
    // this.$http.get(window.API_ROOT + '/api/events/seminar/list')
    //     .then(res => {
    //       this.seminarList = res.body
    //       this.allInfo.push(...res.body)
    //     })
    //
    // this.$http.get(window.API_ROOT + '/api/events/training/list')
    //     .then(res => {
    //       this.trainingList = res.body
    //       this.allInfo.push(...res.body)
    //     })
    //
    // this.$http.get(window.API_ROOT + '/api/events/refresher-course/list')
    //     .then(res => {
    //       this.refresherCourseList = res.body
    //       this.allInfo.push(...res.body)
    //     })

    this.$http.get(window.API_ROOT + `/api/blog-teacher`)
        .then(res => {
          this.advantagesTeacher = res.body
          console.log('this.advantagesTeacher', this.advantagesTeacher)
        })
  }
}
</script>

<style>

@media screen and (max-width: 992px) {

  .teachers-main .title {
    justify-content: flex-start;
  }

  .teachers-main .text {
    font-size: 14px;
  }

  .teachers-main .goals {
    background-color: #FFF;
    padding: 30px;
    border-radius: 20px;

    bottom: -340px;
    left: 30px;
    width: calc(100% - 60px);
  }

  .teachers-main .goals .title {
    color: #32436D;
    font-size: 20px;
  }

  .advantages .text {
    padding-left: 0;
    padding-top: 80px;
  }

  .advantages .text::before {
    top: 40px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .videos {
    display: none;
  }

  .prof-test-img {
    background-image: none;
  }

  .teachers-tests .tests-block .test-item-left {
    padding: 15px;
    padding-top: 80px;
  }

  .teachers-tests .tests-block .test-item-left::before {
    top: 0;
  }

  .teachers-tests .tests-block .test-item-right {
    padding: 15px;
    padding-top: 80px;
  }

  .teachers-tests .tests-block .test-item-right::before {
    top: 0;
  }

  .tests {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    margin-top: 30px;
  }

  .teachers-get-test::after {
    background-image: none;
  }

  .teachers-tests .tests-block {
    padding-bottom: 20px;
  }

  .teachers-tests {
    margin-bottom: 30px;
  }

  .teachers-get-test {
    margin-top: 0;
  }

  .teachers-get-test .test-steps {
    flex-direction: column;
  }

  .teachers-get-test .test-step {
    max-width: 100%;
    border-top: 1px solid #FFF;
    padding-top: 20px;
    padding-left: 20px;
  }

  .test-step-num {
    font-size: 24px;
  }

  .test-step-text {
    font-size: 16px;
  }

  .teachers-get-test .link {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .test-item-right .tests {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .teachers-main .goals {
    bottom: -370px;
  }
}

</style>
